import React from "react";
import Price from "./components/price.jsx";
import Spread from "./components/spread.jsx";
import logo from "./logo.svg";
import "./App.css";
import Websocket from "websocket";
import Binance from "binance-api-node";

class App extends React.Component {
  bitsoWebsocket = new WebSocket("wss://ws.bitso.com");
  // Create an IntrinioRealtime instance
  intrinioWebsocket = new window.IntrinioRealtime({
    public_key: "da94b1739326b0b4827578c56e819288",
    provider: "fxcm"
  });
  binanceWebsocket = Binance();

  constructor() {
    super();
    this.state = {
      btcmxn: {
        currencyPair: "BTC/MXN",
        exchange: "Bitso",
        url: "https://bitso.com",
        time: 0,
        bid: 0,
        ask: 0
      },
      usdmxn: {
        currencyPair: "USD/MXN",
        exchange: "FXCM",
        url: "https://www.fxcm.com",
        time: 0,
        bid: 0,
        ask: 0
      },
      btcusdt: {
        currencyPair: "BTC/USDT",
        exchange: "Binance",
        url: "https://www.binance.com/en",
        time: 0,
        bid: 0,
        ask: 0
      }
    };
  }
  componentWillMount() {
    // update bitso
    this.bitsoWebsocket.onopen = function() {
      this.send(
        JSON.stringify({ action: "subscribe", book: "btc_mxn", type: "orders" })
      );
    };
    this.bitsoWebsocket.onmessage = message => {
      var data = JSON.parse(message.data);

      if (data.type == "trades" && data.payload) {
      } else if (data.type == "diff-orders" && data.payload) {
        console.log(data);
      } else if (data.type == "orders" && data.payload) {
        var maxTimeBid = data.payload.asks.reduce(
          (max, p) => (p.d > max ? p.d : max),
          data.payload.asks[0].d
        );
        var maxTimeOffer = data.payload.bids.reduce(
          (max, p) => (p.d > max ? p.d : max),
          data.payload.bids[0].d
        );
        var maxTime = maxTimeBid > maxTimeOffer ? maxTimeBid : maxTimeOffer;
        var now = new Date();
        var diff = now - maxTime;
        this.setState({
          btcmxn: {
            currencyPair: "BTC/MXN",
            exchange: "Bitso",
            url: "https://bitso.com",
            bid: parseFloat(data.payload.bids[0].r),
            ask: parseFloat(data.payload.asks[0].r),
            time: diff / 1000
          }
        });
      }
    };
    // update Intrinio websocket
    this.intrinioWebsocket.join("fxcm:pair:USD/MXN");
    this.intrinioWebsocket.onQuote(quote => {
      var now = new Date();
      var time = new Date(quote.time.replace(" ", "T"));
      var diff = now - time;
      this.setState({
        usdmxn: {
          currencyPair: "USD/MXN",
          exchange: "FXCM",
          url: "https://www.fxcm.com",
          bid: parseFloat(quote.bid_price),
          ask: parseFloat(quote.ask_price),
          time: diff / 1000
        }
      });
    });
    // update Binance
    this.binanceWebsocket.ws.ticker("BTCUSDT", ticker => {
      var now = new Date();
      var time = new Date(ticker.eventTime);
      var diff = now - time;
      this.setState({
        btcusdt: {
          currencyPair: "BTC/USDT",
          exchange: "Binance",
          url: "https://www.binance.com/en",
          bid: parseFloat(ticker.bestBid),
          ask: parseFloat(ticker.bestAsk),
          time: diff / 1000
        }
      });
    });
  }
  render() {
    return (
      <React.Fragment>
        <section class="sec sec2" id="input-form">
          <div class="container">
            <article class="content text-center mb-50 ">
              <h2>AlliedCrypto </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br class="visible-md visible-lg" />
              </p>
              <main
                role="main"
                className="col-md-10 ml-sm-auto mr-sm-auto col-lg-10 px-4"
              >
                <div className="row" style={{ marginTop: 40 }}>
                  <Price
                    currencyPair={this.state.btcmxn.currencyPair}
                    exchange={this.state.btcmxn.exchange}
                    time={this.state.btcmxn.time}
                    bid={this.state.btcmxn.bid}
                    ask={this.state.btcmxn.ask}
                  />
                  <Price
                    currencyPair={this.state.usdmxn.currencyPair}
                    exchange={this.state.usdmxn.exchange}
                    time={this.state.usdmxn.time}
                    bid={this.state.usdmxn.bid}
                    ask={this.state.usdmxn.ask}
                  />
                  <Price
                    currencyPair={this.state.btcusdt.currencyPair}
                    exchange={this.state.btcusdt.exchange}
                    time={this.state.btcusdt.time}
                    bid={this.state.btcusdt.bid}
                    ask={this.state.btcusdt.ask}
                  />
                </div>
                <div className="row" style={{ marginTop: 40 }}>
                  <Spread
                    title="Bid Spread"
                    arb={
                      ((this.state.usdmxn.bid * this.state.btcusdt.bid) /
                        this.state.btcmxn.bid -
                        1) *
                      100
                    }
                  />
                  <Spread
                    title="Offer Spread"
                    arb={
                      (this.state.btcmxn.ask /
                        this.state.usdmxn.ask /
                        this.state.btcusdt.ask -
                        1) *
                      100
                    }
                  />
                </div>
              </main>
            </article>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default App;
