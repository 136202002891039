import React from "react";

function Spread(props) {
  return (
    <div className="col-md-6">
      <div className="row text-center">
        <h4 className="col-md-12">{props.title}</h4>
        <div className="col-md-6">
          {`Revenue from ${
            props.title == "Bid Spread" ? "buying" : "selling"
          } bitcoin (no fees):`}{" "}
          {props.arb.toFixed(4)}%
        </div>
        <div className="col-md-6">Trade?: {props.arb > 0.5 ? "Yes" : "No"}</div>
      </div>
    </div>
  );
}

export default Spread;
