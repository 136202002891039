import React from "react";

function Price(props) {
  return (
    <div className="col-md-4">
      <div className="row text-center">
        <h4 className="col-md-12">
          {props.currencyPair} (<a href={`${props.url}`}>{props.exchange}</a>)
        </h4>
        <h3 className="col-md-12"></h3>
        {/* <div className="col-md-6"> Delay: {props.time}s</div> */}
        <div
          style={{
            borderRight: "1px solid #930c05",
            borderLeft: "1px solid #930c05",
            backgroundColor: "#f97f6e"
          }}
          className="col-md-10 ml-auto  mr-auto"
        >
          {props.ask.toFixed(2)}
        </div>
        <div
          style={{
            borderRight: "1px solid #01512b",
            borderLeft: "1px solid #01512b",
            backgroundColor: "#2bd896"
          }}
          className="col-md-10 ml-auto mr-auto"
        >
          {props.bid.toFixed(2)}
        </div>
        <div className="col-md-12 text-right" style={{ paddingRight: "25px" }}>
          Spread: &nbsp;
          {(
            ((props.ask - props.bid) / ((props.bid + props.ask) / 2)) *
            100
          ).toFixed(2)}
          %
        </div>
      </div>
    </div>
  );
}

export default Price;
